import type { Theme } from '../../types'
import isPropValid from '@emotion/is-prop-valid'
import { loader } from '../../css/animations'
import { memo } from 'react'
import styled from '@emotion/styled'

/** used to filter out the loading prop from the DOM */
const NonForwardingButton = styled('button', {
  shouldForwardProp: prop => isPropValid(prop) && prop !== 'loading',
})``

// todo: watch progress on :focus-visible for better focus styling
const BaseButton = styled(NonForwardingButton)<{
  loading: boolean
  theme: Theme
}>`
  label: button;
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
  min-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  /* white-space: nowrap; */
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.3em 1.75em;
  font-size: 1.25em;
  line-height: 1.25em;
  border-radius: ${({ theme }) => theme.borderRadius};
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out,
    ${({ loading }) => (loading ? '' : 'color 0.15s ease-in-out')};
  text-transform: capitalize;
  cursor: pointer;
  font-weight: 600;
  min-width: 2.375rem;
  color: ${({ loading }) => (loading ? 'transparent' : 'rgba(0, 0, 0, 0.7)')};
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.75);
  font-family: ${({ theme }) => theme.titleFont};
  box-shadow: ${({ theme }) => theme.boxShadow};

  :hover {
    text-decoration: none;
  }

  :focus {
    outline: none;
    box-shadow: ${({ theme }) => theme.boxShadow},
      0 0 0 5px ${({ theme }) => theme.color.background},
      0 0 0 9px ${({ theme }) => theme.color.tertiary};
  }

  :after {
    content: ${props => (props.loading ? `''` : null)};
    -webkit-animation: ${loader} 500ms infinite linear;
    animation: ${loader} 500ms infinite linear;
    border: 2px solid #fff;
    border-radius: 50%;
    border-color: ${props =>
      props.loading ? 'rgba(0, 0, 0, 0.7)' : 'transparent'};
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    display: block;
    height: 16px;
    width: 16px;
    left: calc(50% - (1.3rem / 2));
    top: calc(50% - (1.3rem / 2));
    -webkit-transform-origin: center;
    transform-origin: center;
    position: absolute;
    padding: 2px;
  }

  :disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
`

const BaseSmallButton = styled(BaseButton)`
  @media (min-width: 992px) {
    font-size: 0.75rem;
    min-width: 1.625rem;
    padding: 0.25rem 0.5rem;
    line-height: 1.33333333;
    min-height: 27px;
    letter-spacing: 0.03em;
  }
`

const BasePrimaryButton = styled(BaseButton)<{
  loading: boolean
  theme: Theme
}>`
  background-color: ${({ theme }) => theme.color.primary || 'inherit'};
  border-color: ${({ theme }) => theme.color.primary || 'inherit'};
  color: ${({ loading }) => (loading ? 'transparent' : '#fff')};
  :hover {
    background-color: ${({ theme }) => theme.color.primaryHover || 'inherit'};
    border-color: ${({ theme }) => theme.color.primaryHover || 'inherit'};
  }
  :after {
    border-color: ${({ loading }) => (!loading ? 'transparent' : '#fff')};
  }
`

// const BaseDangerButton = styled(BaseButton)<{
//   loading: boolean
//   theme: Theme
// }>`
//   background-color: ${({ theme }) => theme.color.danger || 'inherit'};
//   border-color: ${({ theme }) => theme.color.danger || 'inherit'};
//   color: ${props => (props.loading ? 'transparent' : '#fff')};
//   :hover {
//     background-color: ${({ theme }) => theme.color.dangerHover || 'inherit'};
//     border-color: ${({ theme }) => theme.color.dangerHover || 'inherit'};
//   }
//   :after {
//     border-color: ${props => (!props.loading ? 'transparent' : '#fff')};
//   }
// `

const BaseClearButton = styled(BaseButton)<{
  loading: boolean
  theme: Theme
}>`
  border-color: transparent;
  background-color: transparent;
  color: ${props => (props.loading ? 'transparent' : 'rgba(0, 0, 0, 0.45)')};

  :hover {
    background-color: transparent;
    border-color: transparent;
    color: ${props => (props.loading ? 'transparent' : 'rgba(0, 0, 0, 0.7)')};
  }
`

const BaseSmallClearButton = styled(BaseSmallButton)<{
  loading: boolean
  theme: Theme
}>`
  border-color: transparent;
  background-color: transparent;
  color: ${props => (props.loading ? 'transparent' : 'rgba(0, 0, 0, 0.45)')};

  :hover {
    background-color: transparent;
    border-color: transparent;
    color: ${props => (props.loading ? 'transparent' : 'rgba(0, 0, 0, 0.7)')};
  }
`

export const Button = memo(BaseButton)
export const SmallButton = memo(BaseSmallButton)
export const PrimaryButton = memo(BasePrimaryButton)
// export const DangerButton = memo(BaseDangerButton)
export const ClearButton = memo(BaseClearButton)
export const SmallClearButton = memo(BaseSmallClearButton)

export const LargePrimaryButton = styled(PrimaryButton)`
  padding: 0.5em 1.75em;
  font-size: clamp(1em, 1.5vw, 3rem);
`
export const LargeButton = styled(Button)`
  padding: 0.5em 1.75em;
  font-size: clamp(1em, 1.5vw, 3rem);
`
